import styled from "styled-components";
import { Button as MainButton } from "../../../components/Form/Button/styled";
const PADDING_BOTTOM_CONTENT = 10; // same as VirtualTour component. Must match

export const Container = styled.div`
position: relative;
z-index: 1;
&:after {
content: "";
position: absolute;
z-index: -1;
bottom: 0;
left: 0;
width: 100%;
height: 10%; 
background-color: #F1F0EF;
}

@media only screen and (max-width: 768px) {
  padding-bottom: 2rem;
  &:after {
content: "";
position: absolute;
z-index: -1;
bottom: 0;
left: 0;
width: 100%;
height: 100%;
background-color: #F1F0EF;
}

}
`;

export const SideImage = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  @media only screen and (max-width: 768px) {
    height: 350px;
  }
`;

export const Content = styled.div`
  max-width: 100%;
  padding-top: 10px;
  padding-bottom: ${PADDING_BOTTOM_CONTENT}rem;
  position: relative;

  @media only screen and (max-width: 768px) {
    max-width: 100%;
    padding: 30px 40px 50px 40px;
  }
`;

export const Button = styled(MainButton)`
  position: absolute;
  bottom:  ${PADDING_BOTTOM_CONTENT / 2}rem;
  left:50%;
  transform: translateX(-50%);
  z-index: 9;
  /* padding: 10px 64px; */

  @media only screen and (max-width: 768px) {
    left: 50%;
    width: calc(100% - 140px);
    bottom: 0;
    position: relative;
    padding: 1rem;
  }
`;
