import { Col, Row } from "antd";
import Image from "next/image";
import Link from "next/link";
import Framework from "../../../components/Content/Framework";
import Typography from "../../../components/Typography";
import { Button, Content, SideImage, Container } from "./styled";

export default function DesignHomeOffice() {
  return (
    <Container>
      <Row gutter={32}>
        <Col xs={24} md={12}>
          <SideImage>
            <Image
              src="/images/blog/featured.webp"
              layout="fill"
              quality={70}
              objectFit="cover"
              alt="Featured in Phoenix Home and Garden"
            />
          </SideImage>
        </Col>
        <Col xs={24} md={12}>
          <Content>
            <Typography
              fontType="header"
              color="darkGray2"
              fontWeight="bold"
              fontSize="2rem"
              fontFamily="Lora"
            >
              Classy Closets Featured in <br />Phoenix Home + Garden
            </Typography>
            <Typography
              fontFamily="Roboto medium"
            >
              We are thrilled to announce that Classy Closets has been featured in Phoenix Home + Garden for our exceptional and practical pantry design! The article highlights the remarkable work done by our Classy Closets team, showcasing their ingenuity to create functional yet aesthetically pleasing storage solutions.
            </Typography>
            <Link passHref href="/blog/post/classy-closets-featured-in-phoenix-home-garden/">
              <Button variant="outline">ON THE BLOG</Button>
            </Link>
          </Content>

        </Col>
      </Row>
    </Container>
  );
}
